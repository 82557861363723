var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Item',{attrs:{"bladeName":"product-tether","bladesData":_vm.bladesData,"navigation":"product-tethers","onNew":function () { return { isManufactured: true, tetheredProduct: null, tetheredProductID: null }},"title":"Product Tether"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var data = ref.data;
return [_c('v-slide-x-transition',{attrs:{"group":""}},[_c('BT-Field-Select',{key:"1",attrs:{"isEditing":data.isEditing || data.isNew,"itemText":"productName","label":"Triggering Product","navigation":"products"},model:{value:(item.triggeringProductID),callback:function ($$v) {_vm.$set(item, "triggeringProductID", $$v)},expression:"item.triggeringProductID"}}),(item.isManufactured)?_c('BT-Field-Select',{key:"2",attrs:{"isEditing":data.isEditing || data.isNew,"item-text":"productName","label":"Tethered Product","navigation":"products"},model:{value:(item.tetheredProductID),callback:function ($$v) {_vm.$set(item, "tetheredProductID", $$v)},expression:"item.tetheredProductID"}}):_vm._e(),(item.isAcquired)?_c('v-list-item',{key:"3"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Tethered Product")]),_c('v-list-item-title',[_c('BT-Dialog',{attrs:{"item":item,"getOnOpenAsync":_vm.getForeignProductOptions,"width":"650","text":item.tetheredProduct == null ? 'Select Tethered Product' : item.tetheredProduct.productName,"icon":item.tetheredProduct == null ? 'mdi-plus' : null,"label":"Select Acquired Product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"mx-0 px-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('BT-Select-List',{attrs:{"items":item.suppliers,"multiple":false,"cardTitle":"Suppliers"},on:{"change":function($event){return _vm.supplierChanged(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.companyLogoURL(item.seller)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',[_vm._v("mdi-factory")])]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.seller.companyName))])],1)]}}],null,true),model:{value:(item.supplier),callback:function ($$v) {_vm.$set(item, "supplier", $$v)},expression:"item.supplier"}})],1),_c('v-slide-y-transition',[(item.supplier != null)?_c('v-col',{attrs:{"cols":"7"}},[(_vm.isLengthyArray(item.supplier.products))?_c('BT-Select-List',{attrs:{"items":item.supplier.products,"multiple":false,"cardTitle":"Products"},on:{"change":function($event){return _vm.productChanged(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.productLogoURL(item.id)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',[_vm._v("mdi-cube")])]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.productName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.abbreviation))])],1)]}}],null,true),model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}):_vm._e()],1):_vm._e()],1)],1)],1)]}}],null,true)})],1)],1)],1):_vm._e(),_c('BT-Field-Switch',{key:"4",attrs:{"falseText":"On Order Arrival","isEditing":data.isEditing || data.isNew,"trueText":"On Manufacture"},on:{"change":function (res) { item.isManufactured = res; item.isAcquired = !item.isManufactured; }},model:{value:(item.isManufactured),callback:function ($$v) {_vm.$set(item, "isManufactured", $$v)},expression:"item.isManufactured"}}),_c('BT-Field-Number',{key:"5",attrs:{"isEditing":data.isEditing || data.isNew,"label":"Factor"},model:{value:(item.factor),callback:function ($$v) {_vm.$set(item, "factor", _vm._n($$v))},expression:"item.factor"}}),_c('BT-Field-Number',{key:"6",attrs:{"isEditing":data.isEditing || data.isNew,"label":"Quantity per Factor"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}}),_c('v-list-item',{key:"7"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" For every "+_vm._s(_vm._f("toDisplayNumber")(item.factor))+" items adjust tethered product by "+_vm._s(item.quantity)+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }