<template>
    <BT-Blade-Item
        bladeName="product-tether"
        :bladesData="bladesData"
        navigation="product-tethers"
        :onNew="() => { return { isManufactured: true, tetheredProduct: null, tetheredProductID: null }}"
        title="Product Tether">
        <template slot-scope="{ item, data }">
            <v-slide-x-transition group>

            <BT-Field-Select
                v-model="item.triggeringProductID"
                :isEditing="data.isEditing || data.isNew"
                itemText="productName"
                label="Triggering Product"
                navigation="products"
                key="1" />

            <BT-Field-Select
                v-if="item.isManufactured"
                v-model="item.tetheredProductID"
                :isEditing="data.isEditing || data.isNew"
                item-text="productName"
                label="Tethered Product"
                navigation="products"
                key="2" />

            <!-- otherwise select foreign product -->
            <v-list-item v-if="item.isAcquired" key="3">
                <v-list-item-content>
                    <v-list-item-subtitle>Tethered Product</v-list-item-subtitle>
                    <v-list-item-title>
                        <BT-Dialog
                            :item="item"
                            :getOnOpenAsync="getForeignProductOptions"
                            width="650"
                            :text="item.tetheredProduct == null ? 'Select Tethered Product' : item.tetheredProduct.productName"
                            :icon="item.tetheredProduct == null ? 'mdi-plus' : null"
                            label="Select Acquired Product">
                            <template slot-scope="{ item }">
                                <v-container class="ma-0 pa-0">
                                    <v-row dense class="mx-0 px-0">                                
                                        <v-col cols="5">
                                            <BT-Select-List                                        
                                                :items="item.suppliers"
                                                v-model="item.supplier"                                                                                
                                                :multiple="false"
                                                cardTitle="Suppliers"
                                                @change="supplierChanged(item)">
                                                <template slot-scope="{ item }">
                                                    <v-list-item-avatar>
                                                        <v-img :src="companyLogoURL(item.seller)">
                                                            <template v-slot:placeholder>
                                                                <v-icon>mdi-factory</v-icon>
                                                            </template>
                                                        </v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </BT-Select-List>
                                        </v-col>
                                        <v-slide-y-transition>
                                        <v-col cols="7" v-if="item.supplier != null">
                                            <BT-Select-List
                                                v-if="isLengthyArray(item.supplier.products)"
                                                :items="item.supplier.products"
                                                v-model="item.product"                                        
                                                :multiple="false"
                                                cardTitle="Products"
                                                @change="productChanged(item)">
                                                <template slot-scope="{ item }">
                                                    <v-list-item-avatar>
                                                        <v-img :src="productLogoURL(item.id)">
                                                            <template v-slot:placeholder>
                                                                <v-icon>mdi-cube</v-icon>
                                                            </template>
                                                        </v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.productName }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.abbreviation }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </BT-Select-List>
                                        </v-col>
                                        </v-slide-y-transition>
                                    </v-row>
                                </v-container>
                            </template>
                        </BT-Dialog>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
         
            <BT-Field-Switch
                v-model="item.isManufactured"
                @change="res => { item.isManufactured = res; item.isAcquired = !item.isManufactured; }"
                falseText="On Order Arrival"
                :isEditing="data.isEditing || data.isNew"
                trueText="On Manufacture"
                key="4" />

            <BT-Field-Number
                v-model.number="item.factor"
                :isEditing="data.isEditing || data.isNew"
                label="Factor"
                key="5" />
                
            <BT-Field-Number
                v-model.number="item.quantity"
                :isEditing="data.isEditing || data.isNew"
                label="Quantity per Factor"
                key="6" />

            <v-list-item key="7">
                <v-list-item-content>
                    <v-list-item-title>
                        For every {{ item.factor | toDisplayNumber }} items adjust tethered product by {{ item.quantity }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            </v-slide-x-transition>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Product-Tether',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            bladesData: {
                type: Object,
                default: null
            },
            msg: null
        }
    },
    methods: {
        async getForeignProductOptions(item) {
            var suppliers = await this.$BlitzIt.store.getAll('suppliers');
            console.log(suppliers);
            if (suppliers.length == 0) {
                this.msg = 'No Suppliers Found';
                return;
            }

            return {
                data: item,
                suppliers: suppliers.map(x => Object.assign({}, x, { products: null, isLoading: false })),
                supplier: null, //this.isLengthyArray(suppliers) ? suppliers[0].id : null,
                productID: item.tetheredProductID,
                product: null
            }
        },
        supplierChanged(options) {               
            if (options.supplier != null && options.supplier.products == null) {
                //pull products
                options.supplier.isLoading = true;
                this.$BlitzIt.store.getAll('public-products', { companyID: options.supplier.sellerID })
                    .then(res => {
                        console.log(res);
                        options.supplier.products = res;
                    })
                    .finally(() => { options.supplier.isLoading = false; })
            }            
        },
        productChanged(options) {
            if (options.product != null) {
                options.data.tetheredProduct = options.product;
                options.data.tetheredProductID = options.product.id;
            }   
            else {
                options.data.tetheredProduct = null;
                options.data.tetheredProductID = null;
            }                     
        }
    }
}
</script>